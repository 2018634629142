import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from 'aos';
import 'aos/dist/aos.css';
//sections
export default {
  name: "collections",
  components: {},
  data() {
    return {
    }
  },
  created() {
    this.fetchCollectionList()
  },
  computed: {
    ...mapGetters({
      list: 'collections/collectionList',
      listLoading: 'collections/collectionListLoading'
    }),
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  methods: {
    ...mapActions({
      fetchCollectionList: 'collections/GET_COLLECTION_LIST'
    })
  }
}